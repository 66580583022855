import React from "react";
import { Link } from "./link";

const BoxTicket = ({ item }) => {
  return (
    <div className="box-ticket">
      <div className="box-ticket__wrap d-flex flex-column">
        <div className="text-center">
          {item.label && <div className="box-ticket__day">{item.label}</div>}
          {item.date && <div className="box-ticket__date text-uppercase">{item.date}</div>}
          {item.price && (
            <div className="box-ticket__price">
              {item.discount ? (
                <>
                  <span className="line-through">{item.price}€</span> -{" "}
                  <span>{item.discount}€*</span>
                </>
              ) : (
                <span>{item.price}€</span>
              )}
            </div>
          )}
          {item.discount && (
            <span className="box-ticket__discount">*Se acquisti entro il 31 marzo.</span>
          )}
        </div>
        {item.list && (
          <div>
            <ul className="box-ticket__list">
              {item.list.map((child, j) => (
                <li key={j} className="box-ticket__item">
                  {child.name}
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="mt-auto text-center">
          <Link
            to="/acquista/"
            className="btn-link btn-link--small"
            state={{ ticketId: item.id }}
            onClick={() => sessionStorage.removeItem("iid-form-acquista")}
          >
            <span className="btn-link__circle"></span>
            <span className="btn-link__text">
              Acquista <span className="d-block bold">ticket</span>
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BoxTicket;
